// import React, { useState, useEffect } from 'react';
// import { NewsArticle } from './Home';
// import { useLocation } from 'react-router-dom';

// type RecommendedProps = {
//   sport_id: number;
//   exclude_id: number;
// };

// const Recommended: React.FC<RecommendedProps> = ({ sport_id, exclude_id }) => {
//   const [recommendedNews, setRecommendedNews] = useState<NewsArticle[]>([]);
//   const [error, setError] = useState<string | null>(null);
//   const location = useLocation();

//   useEffect(() => {
//     if (window.gtag) {
//       window.gtag('config', 'GTM-NGBQ98PB', {
//         page_path: location.pathname,
//       });
//     }
//   }, [location]);

//   useEffect(() => {
//     const fetchRecommendedNews = async () => {
//       try {
//         // const res = await fetch(`http://localhost:8000/api/news/recommended/${sport_id}`);
//         const res = await fetch(`https://manage.sportski-info.mk/api/news/recommended/${sport_id}`);
//         const data = await res.json();

//         if (Array.isArray(data)) {
//           const filteredNews = data
//             .filter((news: NewsArticle) => news.id !== exclude_id)
//             .slice(0, 5);
//           setRecommendedNews(filteredNews);
//         } else {
//           setRecommendedNews([]);
//         }
//       } catch (error) {
//         setError('Настана грешка при прикажување на вестите');
//       }
//     };

//     if (sport_id) {
//       fetchRecommendedNews();
//     }
//   }, [sport_id, exclude_id]);

//   const truncateText = (text: string, maxLength: number) => {
//     if (text.length > maxLength) {
//       return text.slice(0, maxLength) + '...';
//     }
//     return text;
//   };

//   if (error) {
//     return <p>{error}</p>;
//   }

//   if (recommendedNews.length === 0) {
//     return <p className="text-gray-600">Нема препорачани вести за приказ</p>;
//   }

//   return (
//     <div className="bg-gray-100">
//       <div className="pb-2">
//         <h2 className="pt-3 uppercase font-bold">Можеби ќе ви се допадне</h2>
//       </div>
//       <div className='bg-red-600 py-1 mb-4'></div>
//       <div className="container overflow-y-auto w-full max-h-80 lg:max-h-full">
//         <ul>
//           {recommendedNews.map((news) => (
//             <li key={news.id} className="mb-4">
//               <a href={`/news/${news.id}`} className="flex">
//                 <img
//                   src={`https://manage.sportski-info.mk/uploads/images/${news.image_path}`}
//                   alt={news.title}
//                   width={100}
//                   height={100}
//                   className="w-20 h-20 object-cover rounded-lg mr-4"
//                 />
//                 <div>
//                   <h3 className="font-semibold hover:text-orange-700 duration-300 transition-all lg:text-lg text-md">
//                     {truncateText(news.title, 80)}
//                   </h3>
//                   {/* <p className="text-gray-600">
//                     {news.content.substring(0, 80)}...
//                   </p> */}
//                   <div
//                     className="text-gray-600 text-sm"
//                     dangerouslySetInnerHTML={{ __html: news.content.substring(0, 80) }}
//                   />
//                 </div>
//               </a>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Recommended;
import React, { useState, useEffect } from 'react';
import { NewsArticle } from './Home';
import { useLocation } from 'react-router-dom';

type RecommendedProps = {
  sport_id: number;
  exclude_id: number;
};

const Recommended: React.FC<RecommendedProps> = ({ sport_id, exclude_id }) => {
  const [recommendedNews, setRecommendedNews] = useState<NewsArticle[]>([]);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'GTM-NGBQ98PB', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  useEffect(() => {
    const fetchRecommendedNews = async () => {
      try {
        const res = await fetch(`https://manage.sportski-info.mk/api/news/recommended/${sport_id}`);
        const data = await res.json();

        if (Array.isArray(data)) {
          const filteredNews = data
            .filter((news: NewsArticle) => news.id !== exclude_id)
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) // Sort by date (newest first)
            .slice(0, 5);
          setRecommendedNews(filteredNews);
        } else {
          setRecommendedNews([]);
        }
      } catch (error) {
        setError('Настана грешка при прикажување на вестите');
      }
    };

    if (sport_id) {
      fetchRecommendedNews();
    }
  }, [sport_id, exclude_id]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (recommendedNews.length === 0) {
    return <p className="text-gray-600">Нема препорачани вести за приказ</p>;
  }

  return (
    <div className="bg-gray-100">
      <div className="pb-2">
        <h2 className="pt-3 uppercase font-bold">Можеби ќе ви се допадне</h2>
      </div>
      <div className="bg-red-600 py-1 mb-4"></div>
      <div className="container overflow-y-auto w-full max-h-80 lg:max-h-full">
        <ul>
          {recommendedNews.map((news) => (
            <li key={news.id} className="mb-4">
              <a href={`/news/${news.id}`} className="flex">
                <img
                  src={`https://manage.sportski-info.mk/uploads/images/${news.image_path}`}
                  alt={news.title}
                  width={100}
                  height={100}
                  className="w-20 h-20 object-cover rounded-lg mr-4"
                />
                <div>
                  <h3 className="font-semibold hover:text-orange-700 duration-300 transition-all lg:text-lg text-md">
                    {truncateText(news.title, 80)}
                  </h3>
                  <div
                    className="text-gray-600 text-sm"
                    dangerouslySetInnerHTML={{ __html: news.content.substring(0, 80) }}
                  />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Recommended;
